import React from 'react';
import { UnorderedListOutlined, DeleteOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { Typography } from 'antd';
import { NavLink } from 'react-router-dom';

const { Title } = Typography;

const getMenuItems = (t: Function) => {
  const menu = [
    {
      label: <NavLink to="/">{t('pm-nav:treatment')}</NavLink>,
      key: '2',
      icon: <UnorderedListOutlined style={{ color: '#003a6c' }} />
    },
    {
      label: <NavLink to="/archive">{t('pm-nav:archive')}</NavLink>,
      key: '3',
      icon: <DeleteOutlined style={{ color: '#003a6c' }} />
    }
  ];
  return menu;
};

export { getMenuItems };
