import React, { FC, useState } from 'react';
import { Typography, Row, Col, message } from 'antd';
import { IWarnings } from '../../models/data/IWarnings';
import { CloseOutlined, WarningOutlined } from '@ant-design/icons';
import moment from 'moment';
import { ICasesWarningBanners } from '../../models/components/ICasesWarningBanners';
import i18next from 'i18next';
import { observer } from 'mobx-react';

const { Text } = Typography;

const CasesWarningBanners: FC<ICasesWarningBanners> = ({
  entry,
  casesLoaded,
  medicationTherapyStore,
  patientCareStore,
  parentIndex
}): JSX.Element => {
  const [enabledWarning, setEnabledWarning] = useState(true);

  const dateFormat: string =
    i18next.language === 'de' ? 'DD.MM.YYYY' : 'YYYY-MM-DD';

  /**
   *
   * @param index
   * @param parentIndex
   */
  const closeWarning = async (
    index: number,
    parentIndex: number,
    warningId: string,
    entry: any
  ) => {
    if (!enabledWarning) {
      return;
    } else {
      setEnabledWarning(false);
    }

    const res = await medicationTherapyStore.ackHcpWarning(warningId);

    if (res) {
      await patientCareStore.updateCaseItemList(index, parentIndex);

      // filter id array

      entry?.warnings.splice(index, 1);

      message.success('Erfolgreich geschlossen');
      setEnabledWarning(true);
    } else {
      message.success(
        'Es ist ein Fehler aufgetreten, bitte versuchen Sie es später nochmal.'
      );
    }
  };

  return (
    <Row className="dtb-warnings-grid">
      {casesLoaded &&
        entry?.warnings?.map((warning: IWarnings, index: number) => {
          {
            console.log(JSON.stringify(warning));
          }
          return (
            <Col key={index} span={24} className="dtb-warnings">
              <Text style={{ color: '#fff' }}>
                <WarningOutlined className="dtb-warning-icon" />{' '}
                <p>
                  {`${moment(warning.createdAt).format(dateFormat)} - ${
                    warning.type === 'MEDICATION' ? `Medikation ` : ''
                  } ${warning.reason.reasonObjectName} ${
                    warning.reason.affectedValues
                      ? warning.reason.affectedValues
                          .toString()
                          .replace('.', ',')
                      : ''
                  } ${
                    warning.type === 'MEDICATION'
                      ? ' - nicht eingenommen, Grund: '
                      : ' '
                  } ${
                    warning?.reason?.reasonStateComment
                      ? warning.reason.reasonStateComment
                      : warning.type === 'MEDICATION'
                      ? ' keine Patienteneingabe erfolgt'
                      : ''
                  }`}
                </p>
                <CloseOutlined
                  className="right icon-item dtb-warning-baneer-close"
                  onClick={() =>
                    closeWarning(index, parentIndex, warning.warningId, entry)
                  }
                />
              </Text>
            </Col>
          );
        })}
    </Row>
  );
};

export default observer(CasesWarningBanners);
